import { AxiosResponse } from 'axios'
import queryString from 'query-string'

import { axiosInstance, axiosInstancePrivate } from 'api/api'
import { ISignUpData } from 'components/pageHome/signUpEmailPage/SignUpForm/SignUpForm'
import { ISignUpPhoneData } from 'components/pageHome/signUpPage/SignUpForm/SignUpForm'
import { IUser } from 'redux/reducers/lender/allState/types/state'

export const authApi = {
  login: (data: any) => {
    return axiosInstancePrivate
      .post('auth/login/', {
        username: data.email,
        password: data.password
      })
      .catch(err => err.response)
  },
  googleSignIn: (token: string) => {
    return axiosInstancePrivate
      .post('social_auth/google/', {
        auth_token: token
      })
      .catch(err => err.response)
  },
  facebookSignIn: (token: string) => {
    return axiosInstancePrivate
      .post('social_auth/facebook/', {
        auth_token: token
      })
      .catch(err => err.response)
  },
  logout: (token: string) => {
    return axiosInstance
      .post(
        'auth/logout/',
        {},
        {
          headers: {
            Authorization: `Token ${token}`
          }
        }
      )
      .catch(err => err.response)
  },
  setUser: (token: string): Promise<AxiosResponse<IUser>> =>
    axiosInstance.get('users/current/', {
      headers: {
        Authorization: `Token ${token}`
      }
    }),
  signUpClient: (data: ISignUpData) => {
    const queryParams = queryString.parse(window.location.search)

    return axiosInstancePrivate
      .post(
        'users/register/',
        {
          email: data.email,
          phone_number: data.phone_number,
          password: data.password
        },
        { params: { ...queryParams } }
      )
      .catch(err => err.response)
  },
  sendActivationEmail: (email: string) => {
    return axiosInstancePrivate
      .post('users/register/send_account_activation_email/', { email })
      .catch(err => err.response)
  },
  sendActivationSMS: (userId: number | string) => {
    return axiosInstancePrivate
      .post(`users/${userId}/send-activation-code/`)
      .catch(err => err.response)
  },
  checkActivationSMS: (userId: number | string, data: { sms_code?: string, phone_number?: string }) => {
    return axiosInstancePrivate
      .post(`users/${userId}/check-activation-code/`, data)
      .catch(err => err.response)
  },
  settings: {
    getSettings: () => {
      return axiosInstancePrivate.get('settings').catch(err => err.response)
    },
    patchSettings: (status: boolean) => {
      return axiosInstancePrivate
        .patch('settings/1/', { active: status })
        .catch(err => err.response)
    },
    patchCardStepSettings: (id: number, status: boolean) => {
      return axiosInstancePrivate
        .patch(`settings/${id}/`, { active: status })
        .catch(err => err.response)
    }
  },
  application: {
    getFlowInfo: () => axiosInstancePrivate.get('client/incomplete_application/')
  }
}
