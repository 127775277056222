import { Paper } from '@mui/material'
import React, { useState } from 'react'

import { wavesSVG } from 'components/pageHome/ui/wavesSVG'
import { translateType } from 'translations/types'

import cls from '../AuthPages.module.scss'
import AuthTitle from '../ui/authTitle'

import SignUpForm, { ISignUpPhoneData } from './SignUpForm/SignUpForm'
import SignUpVerification from './SignUpForm/SignUpVerification'

interface IProps {
  t: translateType
}

const SignUpPage: React.FC<IProps> = ({ t }) => {
  const [formStatus, setFormStatus] = useState<{ isSubmitted: boolean; isSuccessful: boolean; dataForm: ISignUpPhoneData | null }>({ isSubmitted: false, isSuccessful: false, dataForm: null })
  const [userId, setUserId] = useState<number | null>(null)

  return (
    <Paper className={cls.paper}>
      <AuthTitle login title={t('homePage.signUp.titleSign')}/>
      {formStatus.isSuccessful && userId
        ? <SignUpVerification userId={userId} formStatus={formStatus} t={t} />
        : <SignUpForm t={t} setFormStatus={setFormStatus} formStatus={formStatus} setUserId={setUserId} />}
      { wavesSVG(cls.waves) }
    </Paper>
  )
}

export default SignUpPage
