import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import TimeUnit from 'components/pageLender/productFormPage/ui/time/TimeUnit'
import { fetchCategories } from 'redux/reducers/lender/productForm/services/fetchCategories'
import { fetchProduct } from 'redux/reducers/lender/productForm/services/fetchProduct'
import { fetchRegions } from 'redux/reducers/lender/productForm/services/fetchRegions'
import { fetchShops } from 'redux/reducers/lender/productForm/services/fetchShops'
import {
  Collateral,
  Commission,
  CreditDecisionSchema, IBonusesCalculation,
  InterestAccrualSchema,
  IssuanceSchema,
  OverdueInterestAccrualSchema,
  ProductBlockSchema,
  ProductFormSchema,
  RepaymentScheduleManagementSchema,
  RevolvingLoanSchema,
  TranchesSchema
} from 'redux/reducers/lender/productForm/types'
import { validateProductForm } from 'redux/reducers/lender/productForm/validation/validateProductForm'

const initialState: ProductFormSchema = {
  data: {
    product: {
      name: '',
      label: 'LOAN',
      loanType: 'moneyOnTheCard',
      regions: [],
      clientType: '1',
      displayOnCalculator: false,
      shops: [],
      categories: [],
      validationErrors: {}
    },
    issuance: {
      loanAmount: {},
      guarantors: {},
      loanTerm: { unit: TimeUnit.Day('1') },
      downPayment: { isAccepted: false },
      validationErrors: {
        downPayment: {},
        loanAmount: {},
        loanTerm: {},
        guarantors: {}
      }
    },
    tranches: {
      isEnabled: false,
      canBeLess: false,
      validationErrors: {}
    },
    revolvingLoan: {
      isEnabled: false,
      validationErrors: {}
    },
    creditDecision: {
      verificationType: '1',
      verifierType: '1'
    },
    interestAccrual: {
      calcMethod: '1',
      repaymentType: '1',
      repaymentIntervalUnit: TimeUnit.Day('1'),
      daysInYear: '1',
      interestGrace: 'grace',
      graceUnit: TimeUnit.Day('1'),
      validationErrors: {}
    },
    overdueInterestAccrual: {
      countingType: 'first',
      interestPeriodUnit: TimeUnit.Day('1'),
      gracePeriodUnit: TimeUnit.Day('1'),
      validationErrors: {}
    },
    repaymentScheduleManagement: {
      principleRepayment: {},
      loanExtension: {},
      repaymentDate: {},
      paymentHoliday: {},
      validationErrors: {
        principleRepayment: {},
        loanExtension: {},
        repaymentDate: {},
        paymentHoliday: {}
      }
    },
    commissions: [],
    collaterals: [],
    bonusesCalculation: {
      bonus_rate: 0,
      min_bonus_repayment: 0,
      max_bonus_repayment: 1,
      validationErrors: {}
    }
  },
  allRegions: [],
  allShops: [],
  allCategories: [],
  error: null
}

function getUpdatedValidationErrors<T extends { [key: string]: any }> (old: T, payload: { [key: string]: any }) {
  const neww = { ...old }

  for (const key in payload) {
    if (neww[key]) {
      if (typeof neww[key] === 'object') {
        // @ts-ignore
        neww[key] = {}
      } else {
        // @ts-ignore
        neww[key] = undefined
      }
    }
  }

  return neww
}

const productFormSlice = createSlice({
  name: 'scoring',
  initialState,
  reducers: {
    reset: (state, action: PayloadAction<void>) => {
      state.data = initialState.data
      state.error = initialState.error
    },
    updateProductBlock:
      (state, action: PayloadAction<Omit<ProductBlockSchema, 'validationErrors'>>) => {
        state.data.product = {
          validationErrors: getUpdatedValidationErrors(state.data.product.validationErrors, action.payload),
          ...action.payload
        }
      },
    updateIssuanceBlock:
      (state, action: PayloadAction<Omit<IssuanceSchema, 'validationErrors'>>) => {
        state.data.issuance = {
          validationErrors: getUpdatedValidationErrors(state.data.issuance.validationErrors, action.payload),
          ...action.payload
        }
      },
    updateTranchesBlock:
      (state, action: PayloadAction<Omit<TranchesSchema, 'validationErrors'>>) => {
        state.data.tranches = {
          validationErrors: getUpdatedValidationErrors(state.data.tranches.validationErrors, action.payload),
          ...action.payload
        }
      },
    updateRevolvingLoanBlock:
      (state, action: PayloadAction<Omit<RevolvingLoanSchema, 'validationErrors'>>) => {
        state.data.revolvingLoan = {
          validationErrors: getUpdatedValidationErrors(state.data.revolvingLoan.validationErrors, action.payload),
          ...action.payload
        }
      },
    updateCreditDecisionBlock:
      (state, action: PayloadAction<CreditDecisionSchema>) => {
        state.data.creditDecision = action.payload
      },
    updateInterestAccrualBlock:
      (state, action: PayloadAction<Omit<InterestAccrualSchema, 'validationErrors'>>) => {
        state.data.interestAccrual = {
          validationErrors: getUpdatedValidationErrors(state.data.interestAccrual.validationErrors, action.payload),
          ...action.payload
        }
      },
    updateOverdueInterestAccrualBlock:
      (state, action: PayloadAction<Omit<OverdueInterestAccrualSchema, 'validationErrors'>>) => {
        state.data.overdueInterestAccrual = {
          validationErrors: getUpdatedValidationErrors(state.data.overdueInterestAccrual.validationErrors, action.payload),
          ...action.payload
        }
      },
    updateRepaymentScheduleManagementBlock:
      (state, action: PayloadAction<Omit<RepaymentScheduleManagementSchema, 'validationErrors'>>) => {
        state.data.repaymentScheduleManagement = {
          validationErrors: getUpdatedValidationErrors(state.data.repaymentScheduleManagement.validationErrors, action.payload),
          ...action.payload
        }
      },
    updateBonusesCalculationBlock:
      (state, action: PayloadAction<Omit<IBonusesCalculation, 'validationErrors'>>) => {
        state.data.bonusesCalculation = {
          validationErrors: getUpdatedValidationErrors(state.data.bonusesCalculation.validationErrors, action.payload),
          ...action.payload
        }
      },
    addCommission:
      (state, action: PayloadAction<Omit<Commission, 'id' | 'validationErrors'>>) => {
        let id = Math.max(...state.data.commissions.map(c => c.id)) + 1
        if (!state.data.commissions.length) id = 1

        state.data.commissions.push({
          id,
          validationErrors: {},
          ...action.payload
        })
      },
    removeCommission:
      (state, action: PayloadAction<number>) => {
        state.data.commissions = state.data.commissions.filter(c => c.id !== action.payload)
      },
    updateCommission:
      (state, action: PayloadAction<Omit<Commission, 'validationErrors'>>) => {
        const idx = state.data.commissions.findIndex(c => c.id === action.payload.id)
        if (idx === -1) return

        state.data.commissions[idx] = {
          validationErrors: getUpdatedValidationErrors(state.data.commissions[idx].validationErrors, action.payload),
          ...action.payload
        }
      },
    addCollateral:
      (state, action: PayloadAction<Omit<Collateral, 'id' | 'validationErrors'>>) => {
        let id = Math.max(...state.data.collaterals.map(c => c.id)) + 1
        if (!state.data.collaterals.length) id = 1

        state.data.collaterals.push({
          id,
          validationErrors: {},
          ...action.payload
        })
      },
    removeCollateral:
      (state, action: PayloadAction<number>) => {
        state.data.collaterals = state.data.collaterals.filter(c => c.id !== action.payload)
      },
    updateCollateral:
      (state, action: PayloadAction<Omit<Collateral, 'validationErrors'>>) => {
        const idx = state.data.collaterals.findIndex(c => c.id === action.payload.id)
        if (idx === -1) return

        state.data.collaterals[idx] = {
          validationErrors: getUpdatedValidationErrors(state.data.collaterals[idx].validationErrors, action.payload),
          ...action.payload
        }
      },
    updateValidationsErrors:
      (state, action: PayloadAction<ReturnType<typeof validateProductForm>>) => {
        if (!action.payload) return

        const f = (stateObj: any, validationObj: any) => {
          if (stateObj.validationErrors) {
            f(stateObj.validationErrors, validationObj)
            return
          }

          for (const key in validationObj) {
            if (typeof stateObj[key] === 'object') {
              f(stateObj[key], validationObj[key])
              continue
            }

            stateObj[key] = validationObj[key]
          }
        }

        debugger // eslint-disable-line no-debugger

        f(state.data, action.payload)
      }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRegions.fulfilled, (state, action) => {
      state.allRegions = action.payload || []
    })
    builder.addCase(fetchRegions.pending, (state, action) => {
      state.allRegions = []
    })
    builder.addCase(fetchRegions.rejected, (state, action) => {
      state.error = action.payload ?? null
      state.allRegions = []
    })

    builder.addCase(fetchShops.fulfilled, (state, action) => {
      state.allShops = action.payload || []
    })
    builder.addCase(fetchShops.pending, (state, action) => {
      state.allShops = []
    })
    builder.addCase(fetchShops.rejected, (state, action) => {
      state.error = action.payload ?? null
      state.allShops = []
    })

    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.allCategories = action.payload || []
    })
    builder.addCase(fetchCategories.pending, (state, action) => {
      state.allCategories = []
    })
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.error = action.payload ?? null
      state.allCategories = []
    })

    builder.addCase(fetchProduct.fulfilled, (state, action) => {
      if (action.payload.commissions.length > 0) {
        action.payload.commissions = action.payload.commissions.map((commission) => {
          if (commission.subtype.toString() === '1' && commission.commission) {
            commission.commission = commission.commission * 100
          }
          return commission
        })
      }
      state.data = action.payload
    })
    builder.addCase(fetchProduct.pending, (state, action) => {
      state.data = initialState.data
    })
    builder.addCase(fetchProduct.rejected, (state, action) => {
      state.error = action.payload || null
      state.data = initialState.data
    })
  }
})

export const { actions: productFormActions } = productFormSlice
export const { reducer: productFormReducer } = productFormSlice
