import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { authApi } from 'api/authApi'
import FormErrorMessage from 'components/common/formErrorMessage/formErrorMessage'
import { LoadingOverlay } from 'components/common/LoadingOverlay/LoadingOverlay'
import cls from 'components/pageHome/signUpEmailPage/SignUpForm/SignUpForm.module.scss'
import { ISignUpPhoneData } from 'components/pageHome/signUpPage/SignUpForm/SignUpForm'
import { checkActivationSMSThunk, loginThunk } from 'redux/reducers/auth/authThunks'
import { loginData } from 'redux/reducers/auth/types/stateTypes'
import { TState } from 'redux/store'
import { translateType } from 'translations/types'
import { getValidation } from 'utils/client/signUpValidation'

interface IProps {
  t: translateType
  userId: number
  authErrors: string[] | null
  loginThunk: (data: loginData) => any
  checkActivationSMSThunk: (userId: string | number, data: { sms_code?: string, phone_number?: string }) => any
  formStatus: { isSubmitted: boolean; isSuccessful: boolean; dataForm: ISignUpPhoneData | null }
}

const SignUpVerification: React.FC<IProps> = ({ t, userId, authErrors, checkActivationSMSThunk, loginThunk, formStatus }) => {
  const [disabled, setDisabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const history = useHistory()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ code: string }>()
  const validation = getValidation(t)

  const onSubmit = async (data: { code: string }) => {
    if (data.code) {
      setIsLoading(true)
      const res = await checkActivationSMSThunk(userId, { sms_code: data.code })
      setIsLoading(false)
      if (res) {
        if (formStatus?.dataForm) {
          await loginThunk({
            email: formStatus?.dataForm.email,
            password: formStatus?.dataForm.password
          })
          history.push('/client/active-loans')
        }
      }
    }
  }

  const handleResendSMS = async () => {
    setDisabled(true)
    const res = await authApi.sendActivationSMS(userId)

    if (res.status === 409) {
      setErrorMessage(res.data.detail)
    } else {
      setTimeout(() => {
        setDisabled(false)
      }, 60000)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" align="center" mb={4}>
        {t('homePage.signUpVerification.checkSMS')}
      </Typography>
      <Box mb={2}>
        <TextField
          className={cls.input}
          variant="standard"
          label={t('homePage.signUp.labelCode')}
          {...register('code', { ...validation.code })}
          type="text"
        />
        <FormErrorMessage errors={errors} name="code"/>
      </Box>
      {authErrors
        ? authErrors.map((err: string, i: number) => {
          return (
            <Typography className={cls.submitError} textAlign="center" color="error" pt={2} mb={4} key={i + err}>
              { err }
            </Typography>
            // TODO: Need update error messages with i18n
          )
        })
        : null}
      <Box sx={{ display: 'flex', justifyContent: 'center' }} mb={4}>
        {isLoading && <LoadingOverlay isVisible={isLoading} />}
        <Button variant="contained" type="submit">{t('homePage.signUpVerification.buttonSMS')}</Button>
      </Box>
      <Typography align="center" mb={2}>{t('homePage.signUpVerification.resendSMS')}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }} mb={4}>
        <Button variant="contained" onClick={handleResendSMS} disabled={disabled}>{t('homePage.signUpVerification.buttonResendSMS')}</Button>
      </Box>
      {errorMessage.length > 0 && (
        <Typography className={cls.submitError} textAlign="center" color="error" pt={2} mb={4}>
          {errorMessage}
        </Typography>
      )}
    </form>
  )
}

const mapStateToProps = (state: TState) => ({
  authErrors: state.auth.authErrors
})

export default connect(mapStateToProps, { checkActivationSMSThunk, loginThunk })(SignUpVerification)
